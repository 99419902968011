import { Outlet, useLocation } from 'react-router-dom';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { getCookie } from '../helpers/getCookie';
import { HeaderUser } from './Header/HeaderUser';
import { useEffect, useState } from 'react';
import { getCheckCookie } from '../helpers/api';
import { Warning } from './Warning/Warning';
import { useAuthentication } from '../helpers/useAuthentication';
// import { PersonalCabinet } from '../pages/PersonalCabinet';

type MeProps = {
    isAuth: boolean;
    userType: string;
  }

export function Layout({isAuth, userType}:MeProps) {
    const location = useLocation();
    const isLoginPage = location.pathname === '/login' || location.pathname === '/registration';
    // const userType = localStorage.getItem('user_type');
    // const [isAuth, setIsAuth] = useState(false);
    // const isAuth = useAuthentication();

    // useEffect(() => {
    //     getCheckCookie().then((res: any) => {
    //         if (res.data.isAuth === true) {
    //             setIsAuth(true);
    //         } else {
    //             setIsAuth(false)
    //         }

    //     }).catch(() => {
    //         setIsAuth(false);
    //     })
    // }, []);

    let headerComponent;

    if (userType && isAuth) {
        headerComponent = <HeaderUser userType={userType}/>;
    } else {
        headerComponent = <Header />;
    }

    return (
        <>
            {/* <Warning /> */}
            {headerComponent}
            {/* <PersonalCabinet/> */}
            <Outlet />
            {isLoginPage ? null : <Footer userType={userType} isAuth={isAuth}/>}
        </>

    )
}