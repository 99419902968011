import React from "react";
import { Link } from "react-router-dom";
import '../NotFound/NotFound.scss';

export function NotFound() {
    return (
        <section className="not-found">
            <div className="container">
                <div className="not-found__container">
                    <h1 data-t="404" className="not-found__title">404</h1>
                    <p className="not-found__txt">Страница не найдена!</p>
                    <p className="not-found__text">Извините, запрашиваемая вами страница не существует.</p>
                    <p className="not-found__text">Возможно, Вы ввели неправильный адрес или страница была удалена.</p>
                    <p className="not-found__text">Вы можете перейти на
                        <a href="/" className="not-found__link"> главную страницу</a> или попробовать поискать другую информацию на нашем сайте.</p>
                </div>
            </div>
        </section>
    )
}