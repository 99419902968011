import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
    name: "authentification",
    initialState: {
        auth: localStorage.getItem("auth") === "true" ? true : false
    },
    reducers: {
        setAuth(state, action) {
            state.auth = action.payload;
            localStorage.setItem("auth", action.payload);
        },
    }
});

export const {setAuth} = slice.actions;
export default slice.reducer;