import { CSSProperties } from "react";
import { PropagateLoader } from "react-spinners";

const override: CSSProperties = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    display: "block",
    zIndex: 100,
  };

  type TLouding = {
    loading: boolean;
  }

export function Loader ({loading}: TLouding) {
    // return (
    //     <PropagateLoader
    //     color={'#f7151d'}
    //     loading={loading}
    //     cssOverride={override}
    //     size={25}
    //     aria-label="Loading Spinner"
    //     data-testid="loader"
    //   />
    // )
    return (
        <div
          style={{
            // Стили для подложки, делающей фон прозрачным
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            display: loading ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
          }}
        >
          <div
            style={{
              pointerEvents: 'none', // Предотвращает взаимодействие с элементами
              opacity: 1, // Прозрачность фона
            }}
          >
            <PropagateLoader
              color={'#f7151d'}
              loading={loading}
              cssOverride={override}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      )
}