import React, { useState } from "react";
import './NoProduct.scss';
import './SettingsCabinet.scss';
import { postNoProduct } from "../../helpers/api";
import { Modal } from "../Modal/Modal";
import AutoResizeTextarea from "../AutoResizeTextarea/AutoResizeTextarea";
import { Loader } from "../Loader/Loader";
import { FileInput } from "../file-input/FileInput";

export const NoProduct = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputKey, setInputKey] = useState(Date.now());
    const [file, setFile] = useState('');
    const [errorMes, setErrorMes] = useState('');
    const [fileSize, setFileSize] = useState(0);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => {
        setIsOpen(false);
        setFormErrors({
            email: '',
            emailIncorrect: '',
            title: '',
            link: '',
        });
        resetFormNoProduct();
    }
    const [valueText, setValueText] = useState('');
    const [valueLink, setValueLink] = useState('');

    const [productData, setProductData] = useState({
        email: '',
        title: '',
        // link: '',
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setProductData((prevUserData) => ({
            ...prevUserData,
            [name]: value,
        }));
    };

    const resetFormNoProduct = () => {
        setProductData({
            email: '',
            title: '',
            // link: '',
        });
        setValueText('');
        setValueLink('');
        setErrorMes('');
        setFile('');
        setFileSize(0);
        setInputKey(Date.now())
    };

    const [formErrors, setFormErrors] = useState({
        email: '',
        emailIncorrect: '',
        title: '',
        link: '',
    });

    const validateForm = () => {
        const errors = {
            email: '',
            emailIncorrect: '',
            title: '',
            link: '',
        };

        // Проверка поля email
        if (!productData.email) {
            errors.email = 'Введите e-mail!';
        } else if (!/^\S+@\S+\.\S+$/.test(productData.email)) {
            errors.emailIncorrect = 'Введите корректный e-mail';
        }

        // Проверка поля firstname
        if (!productData.title) {
            errors.title = 'Введите название товара!';
        }

        // Проверка поля lastname
        if (!valueLink) {
            errors.link = 'Введите ссылку на товар!';
        }

        setFormErrors(errors);

        // Вернуть true, если нет ошибок валидации
        return Object.values(errors).every((error) => error === '');
    };

    const [status, setStatus] = useState(0);
    const extension = file.slice(((file.lastIndexOf(".") - 1) >>> 0) + 2);
    const allowedExtensions = ['txt', 'pdf', 'doc', 'docx', 'rtf', 'xls', 'xlsx', 'csv'];

    const submitFormNoProduct = (event: any) => {
        event.preventDefault()
        const formDate = new FormData(event.target);

        if (validateForm()) {
            if (extension !== '') {
                // if (extension !== 'txt' && extension !== 'pdf' && extension !== 'doc' && extension !== 'docx' && extension !== 'rtf' && extension !== 'xls' && extension !== 'xlsx' && extension !== 'csv') {
                if (!allowedExtensions.includes(extension)) {
                    setStatus(5);
                    setErrorMes('Неправильный формат файла!')
                    return
                }

                if (fileSize > 25 * 1024 * 1024) {
                    setStatus(5);
                    setErrorMes('Объём файла превышает 25 МБ!')
                    return
                }
            }
            postNoProduct(formDate).then((response) => {
                if (response.status === 200) {
                    setLoading(true);
                    setTimeout(() => {
                        setStatus(2);
                        resetFormNoProduct();
                        handleClose();
                        setIsOpenModalSuccess(true);
                        setLoading(false);
                    }, 600)
                }
            })
                .catch(e => {
                    setLoading(true);
                    // if (e.response.status === 500 || e.response.status === 401) {
                    if (e.response.status !== 200 && e.response.status !== 400) {
                        setTimeout(() => {
                            handleClose();
                            setStatus(3);
                            setIsOpenModalError(true);
                            setLoading(false);
                        }, 3000)
                        return
                    } if (e.response.status === 400) {
                        setTimeout(() => {
                            setStatus(5);
                            setErrorMes(e.response.data.error)
                            setLoading(false);
                        }, 1000)
                        return
                    }
                })
        }
    }
    const [isOpenModalSuccess, setIsOpenModalSuccess] = useState(false);
    const [isOpenModalError, setIsOpenModalError] = useState(false);

    return (
        <div className="no-product-header">
            {loading && <Loader loading={loading} />}
            <button onClick={handleOpen} className='no-product-btn'>Не нашли товар?</button>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                <form className='no-product-form' onSubmit={submitFormNoProduct}>
                    <span>Предложить новый товар</span>
                    <div className="login-page__input-wrapper">
                        <label className='login-label' htmlFor="email">Ваш e-mail</label>
                        <input className={`login-page__input ${(formErrors.email && !productData.email) || formErrors.emailIncorrect ? 'error-login' : ''}`}
                            type="text" name="email" id='email'
                            value={productData.email}
                            onChange={handleInputChange} />
                        <div className="error-message">{formErrors.email && !productData.email ? formErrors.email : ''}</div>
                        <div className="error-message">{formErrors.emailIncorrect}</div>
                    </div>
                    <div className="login-page__input-wrapper">
                        <label className="login-label" htmlFor="title">
                            Название товара
                        </label>
                        <input
                            className={`login-page__input ${formErrors.title && !productData.title ? 'error-login' : ''}`}
                            type="text"
                            name="title"
                            id="title"
                            value={productData.title}
                            onChange={handleInputChange}
                        />
                        <div className="error-message">{formErrors.title && !productData.title ? formErrors.title : ''}</div>
                    </div>
                    <div className="login-page__input-wrapper">
                        <label className="login-label" htmlFor="link">
                            Ссылка на товар на другом ресурсе
                        </label>
                        <AutoResizeTextarea name="link" id='link' value={valueLink} setValue={setValueLink}
                            className={`cart-textarea login-page__input ${formErrors.link && !valueLink ? 'error-login' : ''}`}
                        />
                        <div className="error-message">{formErrors.link && !valueLink ? formErrors.link : ''}</div>
                    </div>
                    <div className="login-page__input-wrapper">
                        <label className="login-label" htmlFor="description">
                            Примечания
                        </label>
                        <AutoResizeTextarea id='description' className="cart-textarea" placeholder='Ваш текст' name='description' value={valueText} setValue={setValueText} />
                    </div>
                    <FileInput
                        fileName={file}
                        setFileName={setFile}
                        name='file'
                        setErrorMes={setErrorMes}
                        errorMes={errorMes}
                        status={status}
                        setFileSize={setFileSize}
                        inputKey={inputKey}
                        setInputKey={setInputKey}
                    />
                    <div className='wrapp-confirm-cancel-btn'>
                        <button type='submit' className='no-product-btn'>Отправить</button>
                        <button type='button' onClick={handleClose} className='cancel-btn accepted__order-all-sum-txt'>Отменить</button>
                    </div>
                </form>
            </Modal>
            {status === 2 &&
                <div className="modal-message-wrapp">
                    <Modal isOpen={isOpenModalSuccess} setIsOpen={setIsOpenModalSuccess}>
                        <div className="modal-message">
                            <button type="button" className="btn-close-modal" onClick={() => setIsOpenModalSuccess(false)}>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 10L30 30" stroke="#31465A" strokeWidth="3" />
                                    <path d="M10 30L30 10" stroke="#31465A" strokeWidth="3" />
                                </svg>
                            </button>
                            <span>Ваше сообщение успешно отправлено!</span>
                        </div>
                    </Modal>
                </div>
            }

            {status === 3 &&
                <div className="modal-message-wrapp">
                    <Modal isOpen={isOpenModalError} setIsOpen={setIsOpenModalError}>
                        <div className="modal-message">
                            <button type="button" className="btn-close-modal" onClick={() => setIsOpenModalError(false)}>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 10L30 30" stroke="#31465A" strokeWidth="3" />
                                    <path d="M10 30L30 10" stroke="#31465A" strokeWidth="3" />
                                </svg>
                            </button>
                            <span className="color-red">К сожалению, возникла проблема при отправке вашего сообщения.</span>
                            <span className="color-red">Попробуйте повторить попытку позже.</span>
                        </div>
                    </Modal>
                </div>
            }
        </div>
    )
}