import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Main } from './pages/Main/Main';
import { Layout } from './components/Layout';
import { NotFound } from './components/NotFound/NotFound';
import './scss/normalize.scss';
import { useAuthentication } from './helpers/useAuthentication';

export function App() {
  const {isAuth, userType }= useAuthentication();
  return (
    <Routes>
      <Route path='/' element={<Layout isAuth={isAuth} userType={userType}/>}>
        <Route index element={<Main isAuth={isAuth} userType={userType}/>} />
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  );
}

