import React, { useEffect, useState } from 'react';
// import { Header } from '../../components/Header/Header';
// import { Footer } from '../../components/Footer/Footer';
import { Slider } from '../../components/SliderHero/Slider';
import { Stats } from '../../components/Stats/Stats';
import { VideoTabs } from '../../components/VideoTabs/VideoTabs';
import { Rating, RegistrationSection } from '../../components/Rating/Rating';
import { ProductList } from '../../components/popularPositions/popularPositions';
import { MainVideo } from '../../components/MainVideo/MainVideo';
import { Advantage } from '../../components/Advantage/Advantage';
import { getCheckCookie } from '../../helpers/api';
import { useAuthentication } from '../../helpers/useAuthentication';

type MeProps = {
  isAuth: boolean;
  userType: string;
}

export function Main({isAuth, userType}:MeProps) {

  // const userType = localStorage.getItem('user_type');
  // const [isAuth, setIsAuth] = useState(false);
  // const isAuth = useAuthentication();

  // useEffect(() => {
  //   getCheckCookie().then((res: any) => {
  //     if (res.data.isAuth === true) {
  //       setIsAuth(true);
  //     } else {
  //       setIsAuth(false)
  //     }

  //   }).catch(() => {
  //     setIsAuth(false);
  //   })
  // }, []);

  return (
    <>
      {/* <Slider/> */}
      <MainVideo />
      <Advantage />
      <ProductList userType={userType} isAuth={isAuth} />
      <RegistrationSection userType={userType} isAuth={isAuth} />
      <Stats />
      <VideoTabs />
      <Rating />

    </>
  );
}