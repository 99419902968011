import './MainVideo.scss';

export const MainVideo = () => {
    return (
        <section className="main-video">
            <div className="container">
                <div className="main-video__wrapp">
                    <h1 className="main-video__title">Лучше чем интернет-магазин</h1>
                    <video className='main-video__video' loop autoPlay muted>
                        <source src={process.env.PUBLIC_URL + '/video/Banner.mp4'} type="video/mp4" />
                    </video>
                </div>
            </div>
        </section>
    )
}