// import React, { useState } from 'react';
import { useRef } from 'react';

interface ITextarea {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  className: string;
  placeholder?: string;
  id: string;
}

function AutoResizeTextarea({value, setValue, name, className, placeholder, id}: ITextarea) {

    const textareaRef = useRef<HTMLTextAreaElement>(null);
  
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    };
  
    return (
      <textarea
        ref={textareaRef}
        value={value}
        onChange={handleChange}
        name={name}
        className={className}
        placeholder={placeholder}
        id={id}
      />
    );
  }

export default AutoResizeTextarea;