import React from 'react';
import '../Stats/Stats.scss'

export function Stats() {

    return (
        <section className='stats'>
            <div className='container'>
                <div className='stats__wrapp'>
                    <ul className='stats__list'>
                        <li className="stats__item">
                            <div className="stats__img">
                                <svg width="65" height="77" viewBox="0 0 65 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.51 38L29.51 45H29.6L24.46 71L32 77.24L39.5 71L34.36 45H34.45L35.45 38H28.51Z" fill="url(#paint0_linear_139_477)" />
                                    <path d="M41 40C45.6106 39.9991 50.0799 41.5911 53.6516 44.5068C57.2233 47.4224 59.6779 51.4825 60.6 56H4.4C5.32212 51.4825 7.77673 47.4224 11.3484 44.5068C14.92 41.5911 19.3894 39.9991 24 40H41ZM41 36H24C17.6348 36 11.5303 38.5286 7.02944 43.0294C2.52856 47.5303 0 53.6348 0 60H65C65 53.6348 62.4714 47.5303 57.9706 43.0294C53.4697 38.5286 47.3652 36 41 36Z" fill="black" />
                                    <path d="M32.5 29C40.5081 29 47 22.5081 47 14.5C47 6.49187 40.5081 0 32.5 0C24.4919 0 18 6.49187 18 14.5C18 22.5081 24.4919 29 32.5 29Z" fill="#31465B" />
                                    <defs>
                                        <linearGradient id="paint0_linear_139_477" x1="32" y1="77.24" x2="32" y2="38" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#AF0B0F" stopOpacity="0.3" />
                                            <stop offset="1" stopColor="#AF0B0F" />
                                        </linearGradient>
                                    </defs>
                                </svg>

                            </div>
                            <span className="stats__number">504</span>
                            <span className="stats__text">продавца</span>
                        </li>
                        <li className="stats__item">
                            <div className="stats__img">
                                <svg width="65" height="65" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M49.53 15.87C48.872 12.8294 47.4122 10.0203 45.302 7.73432C43.1919 5.44834 40.5084 3.76879 37.53 2.87V0.87C37.53 -0.29 27.53 -0.29 27.53 0.87V2.87C24.5517 3.76879 21.8682 5.44834 19.7581 7.73432C17.6479 10.0203 16.1881 12.8294 15.53 15.87H12.8V19.87H52.2V15.87H49.53Z" fill="url(#paint0_linear_139_442)" />
                                    <path d="M24 41.8701H41C47.3652 41.8701 53.4697 44.3987 57.9706 48.8996C62.4714 53.4004 65 59.5049 65 65.8701H0C0 59.5049 2.52856 53.4004 7.02943 48.8996C11.5303 44.3987 17.6348 41.8701 24 41.8701Z" fill="#31465B" />
                                    <path d="M41 45.8701C45.6106 45.8692 50.0799 47.4613 53.6516 50.3769C57.2233 53.2925 59.6779 57.3527 60.6 61.8701H4.4C5.32212 57.3527 7.77673 53.2925 11.3484 50.3769C14.92 47.4613 19.3894 45.8692 24 45.8701H41ZM41 41.8701H24C17.6348 41.8701 11.5303 44.3987 7.02943 48.8996C2.52856 53.4004 0 59.5049 0 65.8701H65C65 59.5049 62.4714 53.4004 57.9706 48.8996C53.4697 44.3987 47.3652 41.8701 41 41.8701Z" fill="#31465B" />
                                    <path d="M32.5 9.87012C34.5767 9.87012 36.6068 10.4859 38.3335 11.6397C40.0602 12.7934 41.406 14.4333 42.2007 16.3519C42.9955 18.2706 43.2034 20.3818 42.7983 22.4186C42.3931 24.4554 41.3931 26.3263 39.9246 27.7947C38.4562 29.2632 36.5853 30.2632 34.5485 30.6684C32.5117 31.0735 30.4005 30.8656 28.4818 30.0709C26.5632 29.2761 24.9233 27.9303 23.7696 26.2036C22.6158 24.4769 22 22.4468 22 20.3701C22.0027 17.5862 23.1098 14.917 25.0783 12.9484C27.0469 10.9799 29.716 9.87276 32.5 9.87012ZM32.5 5.87012C29.6322 5.87012 26.8288 6.72053 24.4442 8.31381C22.0597 9.90709 20.2012 12.1717 19.1038 14.8212C18.0063 17.4707 17.7191 20.3862 18.2786 23.1989C18.8381 26.0117 20.2191 28.5953 22.247 30.6232C24.2748 32.651 26.8585 34.032 29.6712 34.5915C32.4839 35.151 35.3994 34.8638 38.0489 33.7664C40.6984 32.6689 42.963 30.8104 44.5563 28.4259C46.1496 26.0414 47 23.2379 47 20.3701C47 16.5245 45.4723 12.8363 42.7531 10.1171C40.0338 7.39779 36.3456 5.87012 32.5 5.87012Z" fill="black" />
                                    <defs>
                                        <linearGradient id="paint0_linear_139_442" x1="32.5" y1="0" x2="32.5" y2="19.87" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#AF0B0F" stopOpacity="0.3" />
                                            <stop offset="1" stopColor="#AF0B0F" />
                                        </linearGradient>
                                    </defs>
                                </svg>

                            </div>
                            <span className="stats__number">1231</span>
                            <span className="stats__text">покупатель</span>
                        </li>
                        <li className="stats__item">
                            <div className="stats__img">
                                <svg width="51" height="60" viewBox="0 0 51 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M47 24H4V36H47V24Z" fill="url(#paint0_linear_139_455)" />
                                    <path d="M51 0H0V10H51V0Z" fill="#31465B" />
                                    <path d="M43.5468 18.4571V48.5429H7.45324V18.4571H43.5468ZM48 14H3V53H48V14Z" fill="black" />
                                    <path d="M18 27H33" stroke="black" strokeWidth="4" strokeMiterlimit="10" />
                                    <defs>
                                        <linearGradient id="paint0_linear_139_455" x1="25.5" y1="36" x2="25.5" y2="24" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#AF0B0F" stopOpacity="0.3" />
                                            <stop offset="1" stopColor="#AF0B0F" />
                                        </linearGradient>
                                    </defs>
                                </svg>

                            </div>
                            <span className="stats__number">30000+</span>
                            <span className="stats__text">товаров</span>
                        </li>
                        <li className="stats__item">
                            <div className="stats__img">
                                <svg width="49" height="65" viewBox="0 0 49 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M34.14 15.11H14.07C13.8249 15.1117 13.5827 15.057 13.3621 14.9502C13.1415 14.8434 12.9484 14.6874 12.7976 14.4941C12.6469 14.3008 12.5426 14.0755 12.4927 13.8355C12.4428 13.5955 12.4487 13.3473 12.51 13.11L15.01 2.66C15.1938 1.90197 15.6269 1.22767 16.2399 0.745297C16.8528 0.262923 17.61 0.000450819 18.39 0H29.82C30.5988 0.000928532 31.3546 0.263771 31.9658 0.746247C32.5771 1.22872 33.0083 1.90278 33.1901 2.66L35.71 13.13C35.768 13.3668 35.771 13.6137 35.7189 13.8519C35.6668 14.09 35.561 14.3132 35.4095 14.5042C35.2581 14.6952 35.0649 14.8491 34.8449 14.9541C34.6249 15.0591 34.3838 15.1124 34.14 15.11Z" fill="url(#paint0_linear_139_465)" />
                                    <path d="M40.32 9.06006C41.3526 9.06271 42.342 9.47477 43.0713 10.2059C43.8005 10.937 44.21 11.9274 44.21 12.9601V52.1901C44.21 53.2218 43.8002 54.2112 43.0706 54.9407C42.3411 55.6702 41.3517 56.0801 40.32 56.0801H7.88998C6.85829 56.0801 5.86886 55.6702 5.13934 54.9407C4.40983 54.2112 4 53.2218 4 52.1901V12.9601C4 11.9274 4.4095 10.937 5.13873 10.2059C5.86796 9.47477 6.85737 9.06271 7.88998 9.06006H40.32ZM40.32 5.06006H7.88998C5.79651 5.06271 3.78968 5.8962 2.3103 7.37745C0.830927 8.8587 -1.6772e-06 10.8666 0 12.9601V52.1901C0 54.2826 0.831251 56.2895 2.31091 57.7691C3.79058 59.2488 5.79743 60.0801 7.88998 60.0801H40.32C42.4125 60.0801 44.4194 59.2488 45.8991 57.7691C47.3787 56.2895 48.21 54.2826 48.21 52.1901V12.9601C48.21 10.8666 47.379 8.8587 45.8997 7.37745C44.4203 5.8962 42.4135 5.06271 40.32 5.06006Z" fill="black" />
                                    <path d="M11.1 25.0601H37.1" stroke="#31465B" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="square" />
                                    <path d="M11.1 35.0601H25.1" stroke="#31465B" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="square" />
                                    <path d="M34.71 52.0601C37.1952 52.0601 39.21 50.0453 39.21 47.5601C39.21 45.0748 37.1952 43.0601 34.71 43.0601C32.2247 43.0601 30.21 45.0748 30.21 47.5601C30.21 50.0453 32.2247 52.0601 34.71 52.0601Z" fill="#31465B" />
                                    <defs>
                                        <linearGradient id="paint0_linear_139_465" x1="24.1" y1="0.02" x2="24.1" y2="15.11" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#AF0B0F" stopOpacity="0.3" />
                                            <stop offset="1" stopColor="#AF0B0F" />
                                        </linearGradient>
                                    </defs>
                                </svg>

                            </div>
                            <span className="stats__number">743</span>
                            <span className="stats__text">заказа оформлено</span>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}