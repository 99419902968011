import React, { useState } from 'react';
import '../VideoTabs/VideoTabs.scss';
import customerPoster from '../../img/customer-img-video.png';
import sellerPoster from '../../img/seller-img-video.png';


export function VideoTabs() {
  const [activeTab, setActiveTab] = useState('one');

  const handleTabClick = (path: string) => {
    setActiveTab(path);
  };

  return (
    <section className='stages'>
      <div className='container'>
        <div className='stages__wrapp-btn'>
          <div className='wrapp-btn-white'>
            <button
              className={`stages__btn ${activeTab === 'one' ? 'tabs-stages--active' : ''}`}
              data-path='one'
              onClick={() => handleTabClick('one')}
            >
              Для покупателя
            </button>
          </div>
          <div className='wrapp-btn-white'>
            <button
              className={`stages__btn ${activeTab === 'two' ? 'tabs-stages--active' : ''}`}
              data-path='two'
              onClick={() => handleTabClick('two')}
            >
              Для продавца
            </button>
          </div>
        </div>
        <div className='stages__container'>
          <div className={`stages__item ${activeTab === 'one' ? 'tabs-item--active' : ''}`} data-target='one'>
            <video className='stages__video' controls poster={customerPoster}>
              <source src={process.env.PUBLIC_URL + '/video/Customer.mp4'} type="video/mp4" />
            </video>
            <div className='stages__list-wrapp'>
              <ol className='stages__list'>
                <li className='stages__list-item'>
                  <b>Соберите список материалов</b> в корзину. Укажите <b>сроки, место доставки</b> и другие пожелания.
                </li>
                <li className='stages__list-item'>
                  Разместите заказ и <b>получите предложения от продавцов</b> под ваш индивидуальный запрос
                </li>
                <li className='stages__list-item'>
                  Выберите тот, который <b>подходит вам</b> больше всего и договоритесь с продавцом о доставке.
                </li>
              </ol>
            </div>
          </div>
          <div className={`stages__item ${activeTab === 'two' ? 'tabs-item--active' : ''}`} data-target='two'>
            <video className='stages__video' controls poster={sellerPoster}>
              <source src={process.env.PUBLIC_URL + '/video/Seller.mp4'} type="video/mp4" />
            </video>
            <div className='stages__list-wrapp'>
              <ol className='stages__list'>
                <li className='stages__list-item'>
                  Выберите интересующий запрос <b>по региону доставки, количеству или номенклатуре</b>.
                </li>
                <li className='stages__list-item'>
                  Укажите <b>свои сроки и цены</b> и другие параметры.
                </li>
                <li className='stages__list-item'>
                  Свяжитесь с покупателям который выбрал <b>ваше предложение</b> и обсудите доставку.
                </li>
                <li className='stages__list-item'>
                  Если <b>сделка прошла успешно</b> - оплатите комиссию портала.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};