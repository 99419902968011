import  './Advantage.scss';

export const Advantage = () => {
    return (
        <section className="advantage">
            <div className="container">
                <div className="advantage__wrapp">
                    <span className="advantage__txt">Создавай заказы со своими условиями и получай предложения от разных продавцов</span>
                    <a href='/about' className="advantage_btn">Как получить преимущества</a>
                </div>
            </div>
        </section>
    )
}