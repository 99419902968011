import { useEffect, useState } from "react";
import { getCheckCookie } from "./api";

export function useAuthentication() {
  const [isAuth, setIsAuth] = useState(false);
  const [userType, setUserType] = useState('');

  useEffect(() => {
    getCheckCookie().then((res: any) => {
      if (res.data.isAuth === true) {
        setIsAuth(true);
        setUserType(res.data.user_type)
      } else {
        setIsAuth(false);
        setUserType('')
      }
    }).catch(() => {
      setIsAuth(false);
      setUserType('')
    });
  }, []);

  return {
    isAuth,
    userType
  };
}