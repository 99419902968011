import axios from 'axios';

// const API_BASE_URL = '/app';

// export const postLogin = async (form: any) => {
//     const response = await axios.post(form.user_type === 1 ? 'http://localhost:3001/login-customer' : 'http://localhost:3001/login-seller', form, {
//         headers: {
//             'Content-Type': 'form/multipart',
//         }
//     });
//     return response;
// };

export const postLogin = async (form: any) => {
    const response = await axios.post('/api/login',  form, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        withCredentials: true
    });
    return response;
};

export const getCustomer = async (customer_id: any, request_id: number) => {
    const response = await axios.get("/api/customer", {
      params: {
        customer_id: customer_id,
        request_id: request_id,
      },
      withCredentials: true
    });
    return response;
  }

  export const getSeller = async (seller_id: any, request_id: number) => {
    const response = await axios.get("/api/seller", {
      params: {
        seller_id: seller_id,
        request_id: request_id,
      },
      withCredentials: true
    });
    return response;
  }

  export const getLogout = async () => {
    const response = await axios.get(`/api/logout`, {
        headers: {
            'Content-Type': 'form/multipart',
        }
    });
    return response;
  };

  export const postNoProduct = async(form: any) => {
    const response = await axios.post(`/api/suggest-product`, form, {
        headers: {
            'Content-Type': 'form/multipart',
        }
    });
    return response;
  }

  export const getCheckCookie = async () => {
    const response = await axios.get("/api/me");
    return response;
  }