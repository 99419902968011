import { Modal } from '../../Modal/Modal';
import YouTube, { YouTubeProps } from 'react-youtube';
import './ModalPopularPosition.scss';
import { useEffect, useState } from 'react';
import { getCheckCookie } from '../../../helpers/api';

interface ModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    userType: string | null;
    isAuth: boolean;
};

export const ModalPopularPosition = ({ isOpen, setIsOpen, userType, isAuth }: ModalProps) => {
    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
        // event.target.playVideo();
    }

    const opts: YouTubeProps['opts'] = {
        // height: '390',
        // width: '640',
        playerVars: {
            autoplay: 1,
            controls: 2,
            fs: 1,
        },
    };

    // const userType = localStorage.getItem('user_type');
    // const [isAuth, setIsAuth] = useState(false);

    // useEffect(() => {
    //     getCheckCookie().then((res: any) => {
    //         if (res.data.isAuth === true) {
    //             setIsAuth(true);
    //         } else {
    //             setIsAuth(false)
    //         }

    //     }).catch(() => {
    //         setIsAuth(false);
    //     })
    // }, []);


    return (
        <div className='modal-popular'>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                <button type="button" className="btn-close-modal" onClick={() => setIsOpen(false)}>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 10L30 30" stroke="#31465A" strokeWidth="3" />
                        <path d="M10 30L30 10" stroke="#31465A" strokeWidth="3" />
                    </svg>
                </button>
                <div className='modal-popular__content'>
                    <div className='txt-wrapp'>
                        <span className='modal-popular__content-txt red'>
                            <a href='/'>coshik.by</a> - это не интернет-магазин, а нечто большее!
                        </span>
                        <span className='modal-popular__content-txt'>Это площадка, на которой продавцы сами предложат Вам цены, под Ваш индивидуальный заказ. </span>
                    </div>
                    <iframe className='video'
                        src="https://www.youtube.com/embed/laD26Sb9be8?si=NzsOtW19sgymh2R2"
                        title="Руководство для покупателей. Как пользоваться порталом Кошык.бел покупателям строительных материалов - YouTube"
                        frameBorder="0"
                        allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    >
                    </iframe>
                    {/* <YouTube videoId="laD26Sb9be8?si=NzsOtW19sgymh2R2" className='video' opts={opts} onReady={onPlayerReady} /> */}
                    <div className='btn-wrapp'>
                        {userType && isAuth ? '' :
                            <a href="/registration" className='btn-link white-btn'>Зарегистрироваться</a>
                        }
                        <a href="/about" className='btn-link grey-btn'>Подробнее</a>
                    </div>
                </div>
            </Modal>
        </div>
    )
}