import React, { useState } from 'react';
import first from '../../img/product-1.jpg';
import second from '../../img/product-2.webp';
import third from '../../img/product-3.jpg';
import fourth from '../../img/product-4.jpg';
import fifth from '../../img/product-5.jpg';
import sixth from '../../img/product-6.jpg';
import seventh from '../../img/product-7.jpg';
import eighth from '../../img/product-8.jpg';
import '../popularPositions/popularPositions.scss';
import { ModalPopularPosition } from './modal-popular-position/ModalPopularPosition';

interface Product {
    id: number;
    name: string;
    manufacturer: string;
    image: string;
    articles: number;
    weight?: (number | string)[];
    link?: string;
}

const products: Product[] = [
    {
        id: 1,
        image: first,
        name: 'Портландцемент БЦК ЦЕМ I 42,5Н 25 кг',
        manufacturer: 'БЦК',
        articles: 1499124,
        weight: [25, ' кг.'],
        link: '/catalog/product-info/12911'
    },
    {
        id: 2,
        image: second,
        name: 'Клей для плитки Тайфун Мастер LUX Plus повышенной фиксации 25 кг',
        manufacturer: ' LUX',
        articles: 1007482,
        weight: [25, ' кг.'],
        link: '/catalog/product-info/4415'
    },
    {
        id: 3,
        image: third,
        name: 'Стеклосетка для штукатурки углов Lihtar 0,2х25 м',
        manufacturer: 'Lihtar',
        articles: 1499055,
        // weight: [3, ' кг.'],
        link: '/catalog/product-info/12771'
    },
    {
        id: 4,
        image: fourth,
        name: 'Блок Керамзит-Новолукомль ТермоКомфорт 490*200*185',
        manufacturer: 'Керамзит-Новолукомль',
        articles: 1449766,
        // weight: [4, ' кг.'],
        link: '/catalog/product-info/12580'
    },
    {
        id: 5,
        image: fifth,
        name: 'Минеральная вата ROCKWOOL Тех Мат 5000x1000x50',
        manufacturer: 'ROCKWOOL',
        articles: 1485918,
        // weight: [500, ' г.'],
        link: '/catalog/product-info/11916'
    },
    {
        id: 6,
        image: sixth,
        name: 'Штукатурка цементная Тайфун Мастер ParoTherm 25 кг',
        manufacturer: 'Тайфун Мастер',
        articles: 1425888,
        weight: [25, ' кг.'],
        link: '/catalog/product-info/13004'
    },
    {
        id: 7,
        image: seventh,
        name: 'Лист кровельный Onduvilla 3D с тенью (зеленый)',
        manufacturer: 'Onduvilla',
        articles: 1396531,
        weight: [1.27, ' кг.'],
        link: '/catalog/product-info/12009'
    },
    {
        id: 8,
        image: eighth,
        name: 'Панель ПВХ Vox Эколайн 2500x250x8',
        manufacturer: 'Vox',
        articles: 1341918,
        // weight: [25, ' кг.'],
        link: '/catalog/product-info/11777'
    },
];

type MeProps = {
    userType: string | null;
    isAuth: boolean;
}

export function ProductList({userType, isAuth}: MeProps) {
    const [isOpen, setIsOpen] = useState(false);
    const modalOpen = () => {
        setIsOpen(!isOpen)
    }
    return (
        <section className='product-list'>
            <div className='container'>
                <h2 className='product-list__title'>Несколько популярных позиций:</h2>
                <div className='product-list__wrapp'>
                    {products.map((product) => (
                        <div key={product.id} className='product-list__item'>
                            <a href={product.link} className='product-list__top'>
                                <img className='product-list__img' src={product.image} alt={product.name} />
                            </a>
                            <div className='product-list__bottom'>
                                <a href={product.link}>
                                    <h3 className='product-list__subtitle'>{product.name}</h3>
                                </a>
                                <p className='product-list__text'>Производитель:
                                    <span className='product-list__textblack'> {product.manufacturer}</span>
                                </p>
                                {/* <p className='product-list__text'>Артикул:
                                    <span className='product-list__textblack'> {product.articles}</span>
                                </p> */}
                                {product.weight &&
                                    <p className='product-list__text'>Вес:
                                        <span className='product-list__textblack'> {product.weight}</span>
                                    </p>
                                }
                                <div className='product-list__btn-wpapp'>
                                    <button onClick={modalOpen} className='product-list__btn'>Почему нет цены?</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <ModalPopularPosition isOpen={isOpen} setIsOpen={setIsOpen} userType={userType} isAuth={isAuth}/>
            </div>
        </section>
    );
};