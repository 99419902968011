import React, { useState, useRef, useEffect } from "react";
import './HeaderUser.scss';
import { useNavigate } from 'react-router-dom';
import { getLogout } from "../../helpers/api";
import { NoProduct } from "../NoProduct/NoProduct";

type MeProps = {
  userType: string;
}

export function HeaderUser({userType}: MeProps) {

  const [isNavVisible, setIsNavVisible] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  // const userType = localStorage.getItem('user_type');

  const handleMouseEnter = () => {
    setIsDropdownVisible(true);
  };

  // const handleMouseLeave = (event: React.MouseEvent) => {
  //   const isMouseOverItem = event.currentTarget.contains(event.relatedTarget as Node);
  //   if (!isMouseOverItem) {
  //     setIsDropdownVisible(false);
  //   }
  // };
  const handleMouseLeave = (event: React.MouseEvent) => {
    const relatedTarget = event.relatedTarget as Node;
    if (relatedTarget && typeof relatedTarget.contains === 'function') {
      const isMouseOverItem = event.currentTarget.contains(relatedTarget);
      if (!isMouseOverItem) {
        setIsDropdownVisible(false);
      }
    }
  };

  const handleMouseEnterHelp = () => {
    setIsHelpVisible(true);
  };

  // const handleMouseLeaveHelp = (event: React.MouseEvent) => {
  //   const isMouseOverItem = event.currentTarget.contains(event.relatedTarget as Node);
  //   if (!isMouseOverItem) {
  //     setIsHelpVisible(false);
  //   }
  // };
  
  if (!isNavVisible) {
    document.body.style.overflow = "auto";
  } else {
    document.body.style.overflow = "hidden";
  }

  const handleMouseLeaveHelp = (event: React.MouseEvent) => {
    const relatedTarget = event.relatedTarget as Node;
    if (relatedTarget && typeof relatedTarget.contains === 'function') {
      const isMouseOverItem = event.currentTarget.contains(relatedTarget);
      if (!isMouseOverItem) {
        setIsHelpVisible(false);
      }
    }
  };

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (navRef.current && !navRef.current.contains(event.target as Node)) {
        setIsNavVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const navigate = useNavigate();
  const logOut = () => {
    getLogout().then(res => {
      if (res.status === 200) {
        localStorage.clear();
        navigate('/', { replace: true, state: null });
        window.location.reload();
      }
    }
    );
  }

  return (
    <header className="header-customer">
      <div className="header__container container" ref={navRef}>
        <div className={`menu-trigger-customer header__nav-burger ${isNavVisible ? 'active-burger' : ''}`} onClick={toggleNav}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <a href="/" className="header__logo-customer" onClick={() => setIsNavVisible(false)}>
          <svg className="logo-desctop" width="157" height="20" viewBox="0 0 157 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_312_241)">
              <path d="M64.8104 19.4762C62.6672 19.4762 60.7888 19.0694 59.1753 18.2557C57.6076 17.511 56.2875 16.3058 55.3799 14.7908C54.4724 13.2758 54.0178 11.5183 54.0729 9.73812C54.0172 7.95778 54.4716 6.20002 55.3792 4.68493C56.2868 3.16984 57.6072 1.96474 59.1753 1.22052C60.7934 0.406841 62.6718 0 64.8104 0C66.949 0 68.8312 0.406841 70.4569 1.22052C71.9968 1.97104 73.3017 3.15266 74.2229 4.63088C75.142 6.16272 75.611 7.93677 75.5731 9.73812C75.6109 11.5387 75.1419 13.312 74.2229 14.843C73.3015 16.3199 71.9966 17.4999 70.4569 18.2486C68.8495 19.0655 66.9674 19.4747 64.8104 19.4762ZM64.8104 14.8667C65.8558 14.8942 66.8942 14.6821 67.8507 14.2458C68.6607 13.8688 69.3452 13.2539 69.8186 12.4778C70.2935 11.6497 70.5323 10.7001 70.5075 9.73812C70.5323 8.77619 70.2935 7.82656 69.8186 6.99846C69.3452 6.22236 68.6607 5.60742 67.8507 5.23048C66.8911 4.81893 65.8622 4.60708 64.823 4.60708C63.7839 4.60708 62.7549 4.81893 61.7954 5.23048C60.9829 5.60844 60.295 6.22289 59.816 6.99846C59.3333 7.82368 59.0902 8.77454 59.1156 9.73812C59.0902 10.7017 59.3333 11.6526 59.816 12.4778C60.295 13.2534 60.9829 13.8678 61.7954 14.2458C62.7434 14.6805 63.7732 14.8926 64.8104 14.8667Z" fill="black" />
              <path d="M104.375 14.4944L102.132 16.8264V0.397949H107.126V19.0755H77.7109V0.397949H82.7054V16.8264L80.4619 14.4849H91.9433L89.9203 16.8264V0.397949H94.917V16.8264L92.8159 14.4849L104.375 14.4944Z" fill="black" />
              <path d="M110.139 19.0755V0.397949H115.135V17.3336L112.89 15.0182H119.937C120.757 15.0182 121.397 14.8025 121.854 14.3688C122.085 14.1474 122.266 13.8769 122.385 13.5761C122.504 13.2753 122.558 12.9514 122.543 12.6269C122.561 12.3016 122.508 11.9764 122.389 11.6747C122.27 11.373 122.087 11.1024 121.854 10.8826C121.395 10.4679 120.757 10.2593 119.937 10.2593H112.456V6.17588H120.252C121.609 6.13985 122.956 6.41675 124.197 6.98641C125.232 7.46979 126.109 8.25268 126.722 9.24023C127.325 10.2583 127.631 11.434 127.604 12.6269C127.63 13.8233 127.324 15.0023 126.722 16.0254C126.113 17.0165 125.235 17.8004 124.197 18.2792C122.954 18.8406 121.607 19.1125 120.252 19.0755H110.139ZM129.106 0.397949H134.103V19.0755H129.106V0.397949Z" fill="black" />
              <path d="M45.2144 12.2904L48.3901 8.26144V8.24485L54.5396 0.397949H49.2581L40.3256 12.1505V0.397949H35.3633V19.0755H39.8755L45.2075 12.2761L45.2144 12.2904Z" fill="black" />
              <path d="M51.2436 13.0894H45.6729L49.1173 19.0758H54.7891L51.2436 13.0894Z" fill="black" />
              <path d="M146.987 12.2904L150.163 8.26144L150.152 8.24485L156.31 0.397949H151.029L142.098 12.1505V0.397949H137.127V19.0755H141.639L146.98 12.2761L146.987 12.2904Z" fill="black" />
              <path className="color-black" d="M153.016 13.0894H147.447L150.892 19.0758H156.561L153.016 13.0894Z" fill="#AF0B0F" />
              <path className="color-black" d="M23.1075 5.05518V5.54813C23.1075 8.04032 22.1483 10.4304 20.4408 12.1927C18.7333 13.955 16.4175 14.945 14.0028 14.945C11.588 14.945 9.27219 13.955 7.56471 12.1927C5.85723 10.4304 4.89798 8.04032 4.89798 5.54813V5.05518H0V5.54813C0 9.38228 1.47577 13.0594 4.10266 15.7705C6.72955 18.4817 10.2924 20.0048 14.0074 20.0048C17.7223 20.0048 21.2852 18.4817 23.9121 15.7705C26.5389 13.0594 28.0147 9.38228 28.0147 5.54813V5.05518H23.1075Z" fill="#AF0B0F" />
              <path className="color-black" d="M20.0836 0H7.9248V5.0551H20.0836V0Z" fill="#AF0B0F" />
            </g>
            <defs>
              <clipPath id="clip0_312_241">
                <rect width="156.552" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <svg className="logo-mobile" width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.6337 6.50195V7.14488C30.6337 10.3953 29.362 13.5126 27.0984 15.8111C24.8348 18.1095 21.7647 19.4007 18.5635 19.4007C15.3623 19.4007 12.2922 18.1095 10.0285 15.8111C7.76494 13.5126 6.49326 10.3953 6.49326 7.14488V6.50195H0V7.14488C0 12.1456 1.95643 16.9414 5.4389 20.4775C8.92137 24.0135 13.6446 26 18.5696 26C23.4945 26 28.2178 24.0135 31.7002 20.4775C35.1827 16.9414 37.1391 12.1456 37.1391 7.14488V6.50195H30.6337Z" fill="#B10207" />
            <path d="M26.9255 0H10.2129V6.49935H26.9255V0Z" fill="#B10207" />
          </svg>
        </a>
        {userType === '1' && (
          <a href='/customer/personal-cabinet' className="header__link header__link-mobail-customer" onClick={() => setIsNavVisible(false)}>
            <span className="header__link-txt">Кабинет покупателя</span>
            {/* <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 0.75L5.5 5.25L10 0.75" stroke="#31465A" strokeWidth="2" />
                                        </svg> */}
          </a>
        )}
        {userType === '2' && (
          <a href='/seller/personal-cabinet' className="header__link header__link-mobail-customer" onClick={() => setIsNavVisible(false)}>
            <span className="header__link-txt">Кабинет продавца</span>
            {/* <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M1 0.75L5.5 5.25L10 0.75" stroke="#31465A" strokeWidth="2" />
                               </svg> */}
          </a>
        )}
        <nav className={`header__nav-customer ${isNavVisible ? 'header__list-visible-customer' : ''}`} ref={navRef}>
          <ul className='header__list-customer header__list-one'>
            <li className="header__item-customer">
              <a href="/catalog" className='header__link' onClick={toggleNav}>
                <span className="catalog-svg">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="svg-color-active" fillRule="evenodd" clipRule="evenodd" d="M1.6665 4.1665C1.6665 3.50346 1.9299 2.86758 2.39874 2.39874C2.86758 1.9299 3.50346 1.6665 4.1665 1.6665H15.8332C16.4962 1.6665 17.1321 1.9299 17.6009 2.39874C18.0698 2.86758 18.3332 3.50346 18.3332 4.1665V15.8332C18.3332 16.4962 18.0698 17.1321 17.6009 17.6009C17.1321 18.0698 16.4962 18.3332 15.8332 18.3332H4.1665C3.50346 18.3332 2.86758 18.0698 2.39874 17.6009C1.9299 17.1321 1.6665 16.4962 1.6665 15.8332V4.1665Z" fill="#31465A" />
                    <path d="M5.8335 5C5.8335 7.76167 7.69933 10 10.0002 10C12.301 10 14.1668 7.76167 14.1668 5H12.5002C12.5002 7.13833 11.1085 8.33333 10.0002 8.33333C8.89183 8.33333 7.50016 7.13833 7.50016 5H5.8335Z" fill="white" />
                  </svg>
                </span>
                Каталог
              </a>
            </li>
            <li className="header__item-customer">
              <a href="/about" className="header__link" onClick={toggleNav}>Как работает</a>
            </li>
            {/* <li className="header__item-customer">
              <a href="/help" className="header__link" onClick={toggleNav}>Помощь</a>
            </li> */}

            <li className="header__item-customer header__item-customer-last display-none"
              onMouseLeave={handleMouseLeaveHelp}
              onMouseEnter={handleMouseEnterHelp}>
              <a href="/help" className="header__link" onClick={toggleNav} onMouseEnter={handleMouseEnterHelp}>Помощь</a>
              {isHelpVisible && (
                <div className="header__dropdown-wrapp-help" onMouseLeave={handleMouseLeaveHelp}>
                  <ul className="header__dropdown">
                    <li>
                      <a href='/help/faq' className="header__link header__link-dropdown">Частые вопросы</a>
                    </li>
                    <li>
                      <a href='/help' className="header__link header__link-dropdown">Пользовательское соглашение</a>
                    </li>

                    <li>
                      <a href='/help/support' className="header__link header__link-dropdown">
                        Служба поддержки
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </li>


            <ul className="sidebar-list-customer ">
              <li className="header__item-customer">
                <a href='/help/faq' className="header__link" onClick={toggleNav}>Частые вопросы</a>
              </li>
              <li className="header__item-customer">
                <a href="/help" className="header__link" onClick={toggleNav}>Пользовательское соглашение</a>
              </li>
              <li className="header__item-customer">
                <a href='/help/support' className="header__link" onClick={toggleNav}>Служба поддержки</a>
              </li>
            </ul>

            <li className="header__item-customer header__item-customer-no-product">
              <NoProduct />
            </li>
          </ul>
          <ul className='header__list-customer'>
            {userType === '1' && (
              <li className="header__item-customer">
                <a href='/customer/personal-cabinet/orders' className="header__link" onClick={toggleNav}>Заказы</a>
              </li>
            )}

            {userType === '2' && (
              <li className="header__item-customer display-none">
                <a href='/seller/personal-cabinet/offer' onClick={toggleNav} className="header__link">Предложения</a>
              </li>
            )}
            {userType === '1' && (
              <li className="header__item-customer">
                <a href='/customer/shopping-cart' className="header__link" onClick={toggleNav}>
                  <svg className="shopping-cart-svg" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 26.667C12 28.1337 10.8 29.3337 9.33333 29.3337C7.86667 29.3337 6.68 28.1337 6.68 26.667C6.68 25.2003 7.86667 24.0003 9.33333 24.0003C10.8 24.0003 12 25.2003 12 26.667ZM22.6667 24.0003C21.2 24.0003 20.0133 25.2003 20.0133 26.667C20.0133 28.1337 21.2 29.3337 22.6667 29.3337C24.1333 29.3337 25.3333 28.1337 25.3333 26.667C25.3333 25.2003 24.1333 24.0003 22.6667 24.0003ZM23.1947 17.3337C23.8013 17.3336 24.3897 17.1266 24.8629 16.747C25.3361 16.3674 25.6657 15.8378 25.7973 15.2457L28 6.66699H9.33333V5.33366C9.33333 4.62641 9.05238 3.94814 8.55229 3.44804C8.05219 2.94794 7.37391 2.66699 6.66667 2.66699H4V5.33366H6.66667V20.0003C6.66667 20.7076 6.94762 21.3858 7.44772 21.8859C7.94781 22.386 8.62609 22.667 9.33333 22.667H25.3333C25.3333 21.9597 25.0524 21.2815 24.5523 20.7814C24.0522 20.2813 23.3739 20.0003 22.6667 20.0003H9.33333V17.3337H23.1947Z" fill="#31465A" />
                  </svg>
                  <h4 className='sidebar__title sidebar__title-mobail-customer'>Корзина</h4>
                </a>
              </li>
            )}
            {userType === '1' && (
              <li className="header__item-customer header__item-customer-last"
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter}
              >
                <a href='/customer/personal-cabinet' className="header__link header__link-last" onMouseEnter={handleMouseEnter}>
                  <span className="header__link-txt">Кабинет покупателя</span>
                  {/* <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 0.75L5.5 5.25L10 0.75" stroke="#31465A" strokeWidth="2" />
                                </svg> */}
                </a>
                {isDropdownVisible && (
                  <div className="header__dropdown-wrapp" onMouseLeave={handleMouseLeave}>
                    <ul className="header__dropdown">
                      <li>
                        <a href="/help" className="header__link header__link-dropdown">Помощь</a>
                      </li>
                      <li>
                        <a href='/customer/personal-cabinet/orders' className="header__link header__link-dropdown">Заказы</a>
                      </li>

                      <li>
                        <a href='/customer/personal-cabinet/settings' className="sidebar__link header__link header__link-dropdown">
                          <span className='sidebar__svg'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.70833 18.3337L7.375 15.667C7.19444 15.5975 7.02417 15.5142 6.86417 15.417C6.70417 15.3198 6.54806 15.2156 6.39583 15.1045L3.91667 16.1462L1.625 12.1878L3.77083 10.5628C3.75694 10.4656 3.75 10.3717 3.75 10.2812V9.71949C3.75 9.62894 3.75694 9.53505 3.77083 9.43783L1.625 7.81283L3.91667 3.85449L6.39583 4.89616C6.54861 4.78505 6.70833 4.68088 6.875 4.58366C7.04167 4.48644 7.20833 4.4031 7.375 4.33366L7.70833 1.66699H12.2917L12.625 4.33366C12.8056 4.4031 12.9758 4.48644 13.1358 4.58366C13.2958 4.68088 13.4519 4.78505 13.6042 4.89616L16.0833 3.85449L18.375 7.81283L16.2292 9.43783C16.2431 9.53505 16.25 9.62894 16.25 9.71949V10.2812C16.25 10.3717 16.2361 10.4656 16.2083 10.5628L18.3542 12.1878L16.0625 16.1462L13.6042 15.1045C13.4514 15.2156 13.2917 15.3198 13.125 15.417C12.9583 15.5142 12.7917 15.5975 12.625 15.667L12.2917 18.3337H7.70833ZM10.0417 12.917C10.8472 12.917 11.5347 12.6323 12.1042 12.0628C12.6736 11.4934 12.9583 10.8059 12.9583 10.0003C12.9583 9.19477 12.6736 8.50727 12.1042 7.93783C11.5347 7.36838 10.8472 7.08366 10.0417 7.08366C9.22222 7.08366 8.53111 7.36838 7.96833 7.93783C7.40556 8.50727 7.12444 9.19477 7.125 10.0003C7.125 10.8059 7.40611 11.4934 7.96833 12.0628C8.53056 12.6323 9.22167 12.917 10.0417 12.917Z" fill="#31465A" />
                            </svg>
                          </span>
                          Настройки
                        </a>
                      </li>
                      <li>
                        <a href='/customer/personal-cabinet/delivery-address' className="header__link header__link-dropdown">
                          Адреса доставки
                        </a>
                      </li>
                      <li>
                        <a href='/customer/personal-cabinet/statistics' className="header__link header__link-dropdown">
                          Статистика
                        </a>
                      </li>
                      <li>
                        <button className='sidebar__link header__link header__link-dropdown' onClick={logOut}>
                          <span className='sidebar__svg'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M4.30432 2.37449C4.11535 2.58032 4 2.85481 4 3.15625V17.5104C4 17.8118 4.11531 18.0862 4.30422 18.292C4.33499 18.3387 4.37059 18.3825 4.4107 18.4226C4.56698 18.5789 4.77895 18.6667 4.99996 18.6667H15C15.221 18.6667 15.4329 18.5789 15.5892 18.4226C15.7455 18.2663 15.8333 18.0543 15.8333 17.8333V2.83333C15.8333 2.61232 15.7455 2.40036 15.5892 2.24408C15.4329 2.0878 15.221 2 15 2H4.99996C4.77895 2 4.56698 2.0878 4.4107 2.24408C4.37063 2.28415 4.33506 2.32789 4.30432 2.37449ZM5.15625 10.2555V10.4112L5.16663 10.3333L9.33329 13.6667V11.1667H14.3333V9.5H9.33329V7L5.16663 10.3333L5.15625 10.2555Z" fill="#31465A" />
                            </svg>
                          </span>
                          Выйти
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
            )}
            {userType === '2' && (
              <li className="header__item-customer header__item-customer-last"
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter}
              >
                <div className="header__link-photo" onMouseEnter={handleMouseEnter}>
                  <a href='/seller/personal-cabinet' className="header__link header__link-last">
                    <span className="header__link-txt">Кабинет продавца</span>
                    {/* <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 0.75L5.5 5.25L10 0.75" stroke="#31465A" strokeWidth="2" />
                                        </svg> */}
                  </a>
                </div>
                {isDropdownVisible && (
                  <div className="header__dropdown-wrapp" onMouseLeave={handleMouseLeave}>
                    <ul className="header__dropdown">
                      <li>
                        <a href="/help" className="header__link header__link-dropdown">Помощь</a>
                      </li>
                      <li>
                        <a href='/seller/personal-cabinet/offer' className="header__link header__link-dropdown">Предложения</a>
                      </li>
                      <li>
                        <a href='/seller/personal-cabinet/settings' className="sidebar__link header__link header__link-dropdown">
                          <span className='sidebar__svg'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.70833 18.3337L7.375 15.667C7.19444 15.5975 7.02417 15.5142 6.86417 15.417C6.70417 15.3198 6.54806 15.2156 6.39583 15.1045L3.91667 16.1462L1.625 12.1878L3.77083 10.5628C3.75694 10.4656 3.75 10.3717 3.75 10.2812V9.71949C3.75 9.62894 3.75694 9.53505 3.77083 9.43783L1.625 7.81283L3.91667 3.85449L6.39583 4.89616C6.54861 4.78505 6.70833 4.68088 6.875 4.58366C7.04167 4.48644 7.20833 4.4031 7.375 4.33366L7.70833 1.66699H12.2917L12.625 4.33366C12.8056 4.4031 12.9758 4.48644 13.1358 4.58366C13.2958 4.68088 13.4519 4.78505 13.6042 4.89616L16.0833 3.85449L18.375 7.81283L16.2292 9.43783C16.2431 9.53505 16.25 9.62894 16.25 9.71949V10.2812C16.25 10.3717 16.2361 10.4656 16.2083 10.5628L18.3542 12.1878L16.0625 16.1462L13.6042 15.1045C13.4514 15.2156 13.2917 15.3198 13.125 15.417C12.9583 15.5142 12.7917 15.5975 12.625 15.667L12.2917 18.3337H7.70833ZM10.0417 12.917C10.8472 12.917 11.5347 12.6323 12.1042 12.0628C12.6736 11.4934 12.9583 10.8059 12.9583 10.0003C12.9583 9.19477 12.6736 8.50727 12.1042 7.93783C11.5347 7.36838 10.8472 7.08366 10.0417 7.08366C9.22222 7.08366 8.53111 7.36838 7.96833 7.93783C7.40556 8.50727 7.12444 9.19477 7.125 10.0003C7.125 10.8059 7.40611 11.4934 7.96833 12.0628C8.53056 12.6323 9.22167 12.917 10.0417 12.917Z" fill="#31465A" />
                            </svg>
                          </span>
                          Настройки
                        </a>
                      </li>
                      <li>
                        <a href='/seller/personal-cabinet/statistics' className="header__link header__link-dropdown">
                          Статистика
                        </a>
                      </li>
                      <li>
                        <button className='sidebar__link header__link header__link-dropdown' onClick={logOut}>
                          <span className='sidebar__svg'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M4.30432 2.37449C4.11535 2.58032 4 2.85481 4 3.15625V17.5104C4 17.8118 4.11531 18.0862 4.30422 18.292C4.33499 18.3387 4.37059 18.3825 4.4107 18.4226C4.56698 18.5789 4.77895 18.6667 4.99996 18.6667H15C15.221 18.6667 15.4329 18.5789 15.5892 18.4226C15.7455 18.2663 15.8333 18.0543 15.8333 17.8333V2.83333C15.8333 2.61232 15.7455 2.40036 15.5892 2.24408C15.4329 2.0878 15.221 2 15 2H4.99996C4.77895 2 4.56698 2.0878 4.4107 2.24408C4.37063 2.28415 4.33506 2.32789 4.30432 2.37449ZM5.15625 10.2555V10.4112L5.16663 10.3333L9.33329 13.6667V11.1667H14.3333V9.5H9.33329V7L5.16663 10.3333L5.15625 10.2555Z" fill="#31465A" />
                            </svg>
                          </span>
                          Выйти
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
            )}
          </ul>
          {userType === '1' && (
            <ul className={`sidebar-list-customer ${isNavVisible ? 'header__list-visible-customer' : ''}`}>
              <li className="sidebar__item  sidebar__item-create">
                <a href='/customer/personal-cabinet/create-an-order' className='sidebar__btn' onClick={toggleNav}>Создать заказ</a>
              </li>
              <li className="sidebar__item">
                <a href='/customer/personal-cabinet/delivery-address' className='sidebar__link' onClick={toggleNav}>
                  <span className='sidebar__svg'>
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 1.25C8.67727 1.25207 6.92979 1.97706 5.64092 3.26592C4.35206 4.55479 3.62707 6.30227 3.625 8.125C3.625 14.0078 9.875 18.4508 10.1414 18.6367C10.2465 18.7103 10.3717 18.7498 10.5 18.7498C10.6283 18.7498 10.7535 18.7103 10.8586 18.6367C11.125 18.4508 17.375 14.0078 17.375 8.125C17.3729 6.30227 16.6479 4.55479 15.3591 3.26592C14.0702 1.97706 12.3227 1.25207 10.5 1.25ZM10.5 5.625C10.9945 5.625 11.4778 5.77162 11.8889 6.04633C12.3 6.32103 12.6205 6.71148 12.8097 7.16829C12.9989 7.62511 13.0484 8.12777 12.952 8.61273C12.8555 9.09768 12.6174 9.54314 12.2678 9.89277C11.9181 10.2424 11.4727 10.4805 10.9877 10.577C10.5028 10.6734 10.0001 10.6239 9.54329 10.4347C9.08648 10.2455 8.69603 9.92505 8.42133 9.51393C8.14662 9.1028 8 8.61945 8 8.125C8 7.46196 8.26339 6.82607 8.73223 6.35723C9.20107 5.88839 9.83696 5.625 10.5 5.625Z" fill="#31465A" />
                    </svg>

                  </span>
                  <h4 className='sidebar__title'>Адреса доставки</h4>
                </a>
              </li>
              <li className="sidebar__item">
                <a href='/customer/personal-cabinet/statistics' className='sidebar__link' onClick={toggleNav}>
                  <span className='sidebar__svg'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.2706 2C11.1213 2 11 2.12128 11 2.27075C11 2.28463 11.0059 2.29668 11.008 2.31015H11V8.72925C11 8.87872 11.1213 9 11.2706 9H17.6898V8.99204C17.7033 8.99408 17.7154 9 17.7294 9C17.8787 9 18 8.87872 18 8.72925C17.9616 5.02946 14.9706 2.03859 11.2706 2Z" fill="#31465A" />
                      <path d="M16 10.9258C16 10.772 15.8754 10.6472 15.7216 10.6472H9.64985C9.576 10.6472 9.50519 10.6178 9.45299 10.5656C9.40078 10.5133 9.37146 10.4425 9.37146 10.3686V4.31915H9.36327C9.36537 4.30528 9.37146 4.29288 9.37146 4.2786C9.37146 4.12459 9.24666 4 9.09287 4C9.08614 4 9.08068 4.00336 9.07417 4.00378V4.00189C9.04937 4.00168 9.02479 4 9 4C5.13411 4 2 7.13411 2 11C2 14.8659 5.13411 18 9 18C12.8659 18 16 14.8659 16 11C16 10.9777 15.9989 10.9557 15.9985 10.9332C15.9985 10.9305 16 10.9284 16 10.9258Z" fill="#31465A" />
                    </svg>

                  </span>
                  <h4 className='sidebar__title'>Статистика</h4>
                </a>
              </li>
              <li className="sidebar__item">
                <a href='/customer/personal-cabinet/settings' className='sidebar__link' onClick={toggleNav}>
                  <span className='sidebar__svg'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.70833 18.3337L7.375 15.667C7.19444 15.5975 7.02417 15.5142 6.86417 15.417C6.70417 15.3198 6.54806 15.2156 6.39583 15.1045L3.91667 16.1462L1.625 12.1878L3.77083 10.5628C3.75694 10.4656 3.75 10.3717 3.75 10.2812V9.71949C3.75 9.62894 3.75694 9.53505 3.77083 9.43783L1.625 7.81283L3.91667 3.85449L6.39583 4.89616C6.54861 4.78505 6.70833 4.68088 6.875 4.58366C7.04167 4.48644 7.20833 4.4031 7.375 4.33366L7.70833 1.66699H12.2917L12.625 4.33366C12.8056 4.4031 12.9758 4.48644 13.1358 4.58366C13.2958 4.68088 13.4519 4.78505 13.6042 4.89616L16.0833 3.85449L18.375 7.81283L16.2292 9.43783C16.2431 9.53505 16.25 9.62894 16.25 9.71949V10.2812C16.25 10.3717 16.2361 10.4656 16.2083 10.5628L18.3542 12.1878L16.0625 16.1462L13.6042 15.1045C13.4514 15.2156 13.2917 15.3198 13.125 15.417C12.9583 15.5142 12.7917 15.5975 12.625 15.667L12.2917 18.3337H7.70833ZM10.0417 12.917C10.8472 12.917 11.5347 12.6323 12.1042 12.0628C12.6736 11.4934 12.9583 10.8059 12.9583 10.0003C12.9583 9.19477 12.6736 8.50727 12.1042 7.93783C11.5347 7.36838 10.8472 7.08366 10.0417 7.08366C9.22222 7.08366 8.53111 7.36838 7.96833 7.93783C7.40556 8.50727 7.12444 9.19477 7.125 10.0003C7.125 10.8059 7.40611 11.4934 7.96833 12.0628C8.53056 12.6323 9.22167 12.917 10.0417 12.917Z" fill="#31465A" />
                    </svg>

                  </span>
                  <h4 className='sidebar__title'>Настройки</h4>
                </a>
              </li>
              <li className="sidebar__item">
                <button className='sidebar__link' onClick={logOut}>
                  <span className='sidebar__svg'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.30432 2.37449C4.11535 2.58032 4 2.85481 4 3.15625V17.5104C4 17.8118 4.11531 18.0862 4.30422 18.292C4.33499 18.3387 4.37059 18.3825 4.4107 18.4226C4.56698 18.5789 4.77895 18.6667 4.99996 18.6667H15C15.221 18.6667 15.4329 18.5789 15.5892 18.4226C15.7455 18.2663 15.8333 18.0543 15.8333 17.8333V2.83333C15.8333 2.61232 15.7455 2.40036 15.5892 2.24408C15.4329 2.0878 15.221 2 15 2H4.99996C4.77895 2 4.56698 2.0878 4.4107 2.24408C4.37063 2.28415 4.33506 2.32789 4.30432 2.37449ZM5.15625 10.2555V10.4112L5.16663 10.3333L9.33329 13.6667V11.1667H14.3333V9.5H9.33329V7L5.16663 10.3333L5.15625 10.2555Z" fill="#31465A" />
                    </svg>
                  </span>
                  <h4 className='sidebar__title'>Выйти из аккаунта</h4>
                </button>
              </li>
            </ul>
          )}
          {userType === '2' && (
            <ul className={`sidebar-list-customer ${isNavVisible ? 'header__list-visible-customer' : ''}`}>
              <li className="sidebar__item">
                <a href='/seller/personal-cabinet/offer' className='sidebar__link' onClick={toggleNav}>
                  <span className='sidebar__svg'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 2H18V5H3V2ZM3.9375 6H17.0625V18H3.9375V6ZM8.15625 9C7.89375 9 7.6875 9.22 7.6875 9.5V11H13.3125V9.5C13.3125 9.22 13.1062 9 12.8438 9H8.15625Z" fill="#31465A" />
                    </svg>
                  </span>
                  <h4 className='sidebar__title'>Предложения</h4>
                </a>
              </li>
              <li className="sidebar__item">
                <a href='/seller/personal-cabinet/statistics' className='sidebar__link' onClick={toggleNav}>
                  <span className='sidebar__svg'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.2706 2C11.1213 2 11 2.12128 11 2.27075C11 2.28463 11.0059 2.29668 11.008 2.31015H11V8.72925C11 8.87872 11.1213 9 11.2706 9H17.6898V8.99204C17.7033 8.99408 17.7154 9 17.7294 9C17.8787 9 18 8.87872 18 8.72925C17.9616 5.02946 14.9706 2.03859 11.2706 2Z" fill="#31465A" />
                      <path d="M16 10.9258C16 10.772 15.8754 10.6472 15.7216 10.6472H9.64985C9.576 10.6472 9.50519 10.6178 9.45299 10.5656C9.40078 10.5133 9.37146 10.4425 9.37146 10.3686V4.31915H9.36327C9.36537 4.30528 9.37146 4.29288 9.37146 4.2786C9.37146 4.12459 9.24666 4 9.09287 4C9.08614 4 9.08068 4.00336 9.07417 4.00378V4.00189C9.04937 4.00168 9.02479 4 9 4C5.13411 4 2 7.13411 2 11C2 14.8659 5.13411 18 9 18C12.8659 18 16 14.8659 16 11C16 10.9777 15.9989 10.9557 15.9985 10.9332C15.9985 10.9305 16 10.9284 16 10.9258Z" fill="#31465A" />
                    </svg>

                  </span>
                  <h4 className='sidebar__title'>Статистика</h4>
                </a>
              </li>
              <li className="sidebar__item">
                <a href='/seller/personal-cabinet/settings' className='sidebar__link' onClick={toggleNav}>
                  <span className='sidebar__svg'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.70833 18.3337L7.375 15.667C7.19444 15.5975 7.02417 15.5142 6.86417 15.417C6.70417 15.3198 6.54806 15.2156 6.39583 15.1045L3.91667 16.1462L1.625 12.1878L3.77083 10.5628C3.75694 10.4656 3.75 10.3717 3.75 10.2812V9.71949C3.75 9.62894 3.75694 9.53505 3.77083 9.43783L1.625 7.81283L3.91667 3.85449L6.39583 4.89616C6.54861 4.78505 6.70833 4.68088 6.875 4.58366C7.04167 4.48644 7.20833 4.4031 7.375 4.33366L7.70833 1.66699H12.2917L12.625 4.33366C12.8056 4.4031 12.9758 4.48644 13.1358 4.58366C13.2958 4.68088 13.4519 4.78505 13.6042 4.89616L16.0833 3.85449L18.375 7.81283L16.2292 9.43783C16.2431 9.53505 16.25 9.62894 16.25 9.71949V10.2812C16.25 10.3717 16.2361 10.4656 16.2083 10.5628L18.3542 12.1878L16.0625 16.1462L13.6042 15.1045C13.4514 15.2156 13.2917 15.3198 13.125 15.417C12.9583 15.5142 12.7917 15.5975 12.625 15.667L12.2917 18.3337H7.70833ZM10.0417 12.917C10.8472 12.917 11.5347 12.6323 12.1042 12.0628C12.6736 11.4934 12.9583 10.8059 12.9583 10.0003C12.9583 9.19477 12.6736 8.50727 12.1042 7.93783C11.5347 7.36838 10.8472 7.08366 10.0417 7.08366C9.22222 7.08366 8.53111 7.36838 7.96833 7.93783C7.40556 8.50727 7.12444 9.19477 7.125 10.0003C7.125 10.8059 7.40611 11.4934 7.96833 12.0628C8.53056 12.6323 9.22167 12.917 10.0417 12.917Z" fill="#31465A" />
                    </svg>

                  </span>
                  <h4 className='sidebar__title'>Настройки</h4>
                </a>
              </li>
              <li className="sidebar__item">
                <button className='sidebar__link' onClick={logOut}>
                  <span className='sidebar__svg'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.30432 2.37449C4.11535 2.58032 4 2.85481 4 3.15625V17.5104C4 17.8118 4.11531 18.0862 4.30422 18.292C4.33499 18.3387 4.37059 18.3825 4.4107 18.4226C4.56698 18.5789 4.77895 18.6667 4.99996 18.6667H15C15.221 18.6667 15.4329 18.5789 15.5892 18.4226C15.7455 18.2663 15.8333 18.0543 15.8333 17.8333V2.83333C15.8333 2.61232 15.7455 2.40036 15.5892 2.24408C15.4329 2.0878 15.221 2 15 2H4.99996C4.77895 2 4.56698 2.0878 4.4107 2.24408C4.37063 2.28415 4.33506 2.32789 4.30432 2.37449ZM5.15625 10.2555V10.4112L5.16663 10.3333L9.33329 13.6667V11.1667H14.3333V9.5H9.33329V7L5.16663 10.3333L5.15625 10.2555Z" fill="#31465A" />
                    </svg>

                  </span>
                  <h4 className='sidebar__title'>Выйти из аккаунта</h4>
                </button>
              </li>
            </ul>
          )}
        </nav>
      </div>
    </header>
  )
}

